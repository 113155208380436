import React from "react";
import Slider from "react-slick";

const ParishVideos = () => {

    const peramburvideo = [
        {
            id: 1,
            title: "Lourdes Shrine Perambur History Part 1",
            src: "https://www.youtube.com/embed/m_DQsfzQYCs?si=x9E08iPNNHVRxqZn",
        },
        {
            id: 2,
            title: "Lourdes Shrine Perambur History Part 2",
            src: "https://www.youtube.com/embed/Cr7erv4h-3I?si=QbH1cfGwL0PVF5yU",
        },
    ];

    return (
        <div className="container mb-5">
            <div className="row">
                <Slider
                    dots={true}
                    infinite={true}
                    loop={true}
                    autoplay={false}
                    autoplaySpeed={3000}
                    pauseOnHover={true}
                    pauseOnFocus={true}
                    swipeToSlide={true}
                    speed={500}
                    slidesToShow={2}
                    slidesToScroll={1}
                    responsive={[
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                                infinite: true,
                                dots: true,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                initialSlide: 2,
                            },
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            },
                        },
                    ]}
                >
                    {peramburvideo?.map((peramburvideo, index) => (
                        <div className="col-lg-3 mt-2" id="slideryoutube" key={index}>
                            <iframe
                                src={peramburvideo?.src}
                                title={peramburvideo?.title}
                                id="video-frames"
                                style={{
                                    width: "100%",
                                    height: "300px",
                                    border: "none",
                                    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                                    padding: "4px",
                                }}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            />
                            <p className="text-center">{peramburvideo?.title}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ParishVideos;