import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiUrl } from '../../API/ApiUrl';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
// import ReCAPTCHA from "react-google-recaptcha";


const PrayerRequest = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const location = useLocation();
  const url = location.pathname;
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  // const recaptchaRef = useRef();
  useEffect(() => {
    const path = {
      "/prayer-request": 11,
      "/thanks-giving": 12
    };
    setId(path[url] ? path[url] : url);
  }, [url]);

  useEffect(() => {
    const path = {
      "/prayer-request": 11,
      "/thanks-giving": 12
    };
    setId(path[url] ? path[url] : url);
  }, [url]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/Pages`);
        const newPages = response?.data?.pages;
        const cachedData = JSON.parse(localStorage.getItem("Pages"));
    
        if (cachedData && JSON.stringify(cachedData) !== JSON.stringify(newPages)) {
          localStorage.setItem("PagesPrayer", JSON.stringify(newPages)); 
        }
    
        setData(newPages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };    

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="text-center mt-5">
        loading...
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="text-center mt-5">
        No data found...
      </div>
    );
  }
  const filterdata = Array.isArray(data) ? data.filter((item) => item.id === id) : [];


  const images = {
    11: 'assets/images/prayer.png',
    12: 'assets/images/thanks.jpg',
  }
  const type = {
    11: 'Prayer Request',
    12: 'Thanksgiving',
  }

  const onSubmitContactForm = (data, e) => {


    Swal.fire(
      "Thank you for contacting us. We will get in touch with you shortly.",
      "",
      "success"
    );

    data.type = type[id];

    axios
      .post(`${ApiUrl}/store/contactform/category`, data)
      .then((res) => {
        e.target.reset();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: err.message,
        });
      });
  };
  return (
    <>
      <div className="container subpage">
        <div className="row">
          <h4 className="heading text-center mb-4">{type[id]}</h4>
          <div className="col-lg-6">
            {filterdata.map((item) => (
              <div key={item.id}>
                <div
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{
                    __html: `<style>table { width: 100%; max-width: 100%; border-collapse: collapse; } table, th, td { border: 1px solid #ddd; padding: 8px; text-align: left; } @media (max-width: 600px) { table, th, td { display: block; width: 100%; box-sizing: border-box; } } </style>${item.content}`,
                  }}
                />
              </div>
            ))}
            <br />
            <img src={images[id]} style={{ width: '100%' }} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="contacts-form">
              <form id="contactForm" onSubmit={handleSubmit(onSubmitContactForm)} >
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <div className="form-group">
                      <label>Your Name <span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        {...register("name", { required: true })}
                        aria-invalid={errors?.name ? "true" : "false"}
                      />
                      {errors?.name?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">Name is required</label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <div className="form-group">
                      <label>Your Phone <span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name="mobile"
                        {...register("mobile", { required: true })}
                        aria-invalid={errors?.mobile ? "true" : "false"}
                        className="form-control"
                      />
                      {errors?.mobile?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">Phone is required</label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <div className="form-group">
                      <label>Your Email <span className='text-danger'>*</span></label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        {...register("email", { required: true })}
                        aria-invalid={errors?.email ? "true" : "false"}
                      />
                      {errors?.email?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">Email is required</label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Your Message <span className='text-danger'>*</span></label>
                      <textarea
                        name="message"
                        className="form-control"
                        id="message"
                        cols={15}
                        rows={4}
                        {...register("message", { required: true })}
                        aria-invalid={errors?.message ? "true" : "false"}
                        defaultValue={""}
                      />
                      {errors?.message?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">Message is required</label>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-lg-12 col-sm-12">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LeHe7QpAAAAAJeceRufyEvhILfPoTuEQGOvEBBC"
                      onChange={(value) => {
                        setValue("recaptcha", value, { shouldValidate: true });
                      }}
                      style={{ maxWidth: "300px", margin: "0 auto" }}
                    />
                    {errors.recaptcha && (
                      <span className="text-danger">
                        Please complete the reCAPTCHA verification.
                      </span>
                    )}
                    </div> */}
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        name="gridCheck"
                        defaultValue="I agree to the terms and privacy policy."
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        required=""
                      />
                      <label className="form-check-label" htmlFor="gridCheck">
                        I agree to the <a href="terms-conditions.html">Terms</a> and{" "}
                        <a href="privacy-policy.html">Privacy Policy</a>
                      </label>
                      <div className="help-block with-errors gridCheck-error" />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <button type="submit" className="default-btn">
                      <span>Send Message</span>
                    </button>
                    <div id="msgSubmit" className="h3 text-center hidden" />
                    <div className="clearfix" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default PrayerRequest
