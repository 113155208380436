import React, { useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import Slider from "./Components/Slider";
import News from "./Components/News";
import Counter from "./Components/Counter";
import Header from "./layout/partials/Header";
import Footer from "./layout/partials/Footer";
import axios from "axios";
import { ApiUrl } from "./API/ApiUrl";
import MassTime from "./Components/MassTime";
import AllPriest from "./Components/AllPriest";
import Buttons from "./Components/Buttons";
import Scrollbar from "./Components/Scrollbar";
import Youtube from "./Components/Youtube";
import ParishEvents from "./Components/ParishEvents";
import ChurchHistory from "./Components/ChurchHistory";
import ParishVideos from "./Components/ParishVideos";
import HomeBar from "./Components/HomeBar";
import Striptext from "./Components/Striptext";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [homedata, setHomedata] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = JSON.parse(localStorage.getItem("HomeData"));
        const cachedTimestamp = localStorage.getItem("HomeDataTimestamp");
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        const apiData = response?.data?.data;
        const apiTimestamp = new Date().toISOString();
        if (
          cachedData &&
          cachedTimestamp &&
          new Date(apiTimestamp) <= new Date(cachedTimestamp)
        ) {
          setHomedata(cachedData);
        } else {
          localStorage.setItem("HomeData", JSON.stringify(apiData));
          localStorage.setItem("HomeDataTimestamp", apiTimestamp);
          setHomedata(apiData);
        }
      } catch (error) {
        let collection = localStorage.getItem("HomeData");
        setHomedata(collection ? JSON.parse(collection) : null);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (homedata) {
      const totalImages = document.querySelectorAll("img").length;
      if (totalImages === 0) {
        setLoading(false);
      }
    }
  }, [homedata]);

  const handleImageLoad = () => {
    setImagesLoaded((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount === document.querySelectorAll("img").length) {
        setLoading(false);
      }
      return newCount;
    });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ThreeDots
          visible={true}
          height="50"
          width="50"
          color="#007A9E"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  return (
    <>
      <Header menudata={homedata?.headermenudata} />
      <Slider sliderdata={homedata?.SlidesData} onLoad={handleImageLoad} />
      <Scrollbar />
      <Counter />
      <HomeBar />
      <ChurchHistory />
      <ParishVideos />
      <News
        allnewsdata={homedata?.newsdata}
        announcementdata={homedata?.announcementdata}
        onLoad={handleImageLoad}
      />
      <AllPriest allpriestdata={homedata?.teammembers} onLoad={handleImageLoad} />
      <MassTime />
      <Buttons />
      <ParishEvents events={homedata?.parishnewsdata} onLoad={handleImageLoad} />
      <br/>
      <Youtube events={homedata?.parishnewsdata}/>
      <br/>
      <Striptext />
      <Footer footerdata={homedata?.footercontactdata} />
    </>
  );
};

export default Home;
