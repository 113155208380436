import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const News = ({ allnewsdata, announcementdata }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getFilteredAndSortedNews = (announcementdata) => {
    if (!announcementdata || announcementdata.length === 0) return null;
    const filteredAnnouncement = announcementdata.filter(event => event.category_id === 17);
    if (filteredAnnouncement.length === 0) return null;
    const sortedNews = filteredAnnouncement.sort((a, b) => new Date(b.date) - new Date(a.date));

    return sortedNews[0];
  };

  const latestNews = getFilteredAndSortedNews(announcementdata); 

  useEffect(() => {
    const newsevents = localStorage.getItem("HomeData");
    if (newsevents) {
      setData(JSON.parse(newsevents));
      setLoading(false);
    } else {
      setData(allnewsdata);
    }
  }, [allnewsdata]);

  const latestEvents = data?.newsdata
    ? data?.newsdata
      .filter((news) => news.category_id === 13)
      .sort((a, b) => new Date(b.eventdate) - new Date(a.eventdate))
      .slice(0, 3)
    : [];

  const noimage = "assets/images/img/noimage.webp";

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    const formattedDate = new Date(`${year}-${month}-${day}`);
    const dayOfMonth = formattedDate.getDate().toString().padStart(2, "0");
    const monthName = formattedDate.toLocaleDateString("en-US", {
      month: "long",
    });

    return {
      day: dayOfMonth,
      month: monthName,
      year: formattedDate.getFullYear(),
    };
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="events-area pb-40 mt-3">
      <div className="container">
        {/* <div className="section-title text-center">
          <h4 className="our-title">Parish Jubilee</h4>
        </div> */}
        <div className="row justify-content-center align-items-center mt-3">
          {/* Left side image */}
          {latestNews && (
            <div className="col-lg-4 col-md-4">
              <div className="titles">
                <h5 className="text-center mb-3">
                  {latestNews.title}
                </h5>
              </div>
              <img
                src={latestNews.file_id}
                alt="Left Side"
                className="img-fluid"
                data-bs-toggle="modal"
                title="Click to enlarge image"
                data-bs-target="#staticBackdrop"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  maxWidth: "100%",
                  height: "auto",
                  cursor: "pointer",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.05)")
                }
                onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
              />
            </div>
          )}

          {/* Center dynamic content */}
          <div className="col-lg-4 col-md-4">
            <div className="row justify-content-center">
              {latestEvents?.map((news, index) => (
                <div className="col-lg-12 col-md-12 mt-3" key={index}>
                  <div className="single-events-card newscards style-4">
                    <div className="events-image eventsimages news-image">
                      <Link to={`ournews?newsid=${news?.id}`}>
                        <img
                          src={news?.media_url || noimage}
                          alt="No News"
                          style={{ width: "100%" }}
                        />
                      </Link>
                      <div className="date">
                        <p className="month">
                          {formatDate(news?.eventdate).year}-2025
                        </p>
                      </div>
                    </div>
                    <div className="events-content">
                      <br />
                      <br />
                      <Link
                        to={`ournews?newsid=${news?.id}`}
                        className="latestnews"
                      >
                        <h4 className="text-center newstitle latest-title">
                          {news?.title}
                        </h4>
                        <div
                          dangerouslySetInnerHTML={{ __html: news?.content }} className="mt-2" id="newscontent"
                        />
                      </Link>
                      <br />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right side image */}
          {latestNews && (
            <div className="col-lg-4 col-md-4">
              <div className="titles">
                <h5 className="text-center mb-3">
                  {latestNews.etitle}
                </h5>
              </div>
              <img
                src={latestNews.file_image}
                alt="Right Side"
                data-bs-toggle="modal"
                title="Click to enlarge image"
                data-bs-target="#staticBackdrop2"
                className="img-fluid"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  maxWidth: "100%",
                  height: "auto",
                  cursor: "pointer",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.05)")
                }
                onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
              />
            </div>
          )}
        </div>
      </div>
      <>
        {/* Modal */}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  {latestNews?.title}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body text-center modal-scrollbar">
                <img
                  src={latestNews?.file_id}
                  alt="Left Side"
                  className="img-fluid"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>

      {/* 2nd image */}
      <>
        {/* Modal */}
        <div
          className="modal fade"
          id="staticBackdrop2"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel2"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel2">
                  {latestNews?.etitle}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body text-center modal-scrollbar">
                <img
                  src={latestNews?.file_image}
                  alt="Left Side"
                  className="img-fluid"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default News;
