import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const ParishEvents = ({ events }) => {
  const modalRef = useRef(null);

  const getFilteredAndSortedEvent = (events) => {
    if (!events || events.length === 0) return [];
    const filteredEvents = events.filter((event) => event.category_id === 16);
    return filteredEvents.sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  const filteredAndSortedEvents = getFilteredAndSortedEvent(events);

  const latestEvent = filteredAndSortedEvents[0];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const showModal = () => {
    const modal = new window.bootstrap.Modal(modalRef.current);
    modal.show();
  };

  if (!latestEvent) {
    return <p>No event data available.</p>;
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: latestEvent.images.length > 1,
  };

  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="section-title text-center">
            <h4 className="our-title mb-3">Parish News</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          {latestEvent?.images && latestEvent?.images.length > 0 ? (
            <Slider {...settings}>
              {latestEvent.images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`slide-${index}`}
                    className="img-fluid"
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      maxWidth: "100%",
                      height: "auto",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.transform = "scale(1.05)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    }
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <p>No images found.</p>
          )}
        </div>
        <div className="col-lg-8">
          <h5 className="parish-event-title">{latestEvent?.title}</h5>
          <p className="parish-eventdata-data">
            <i className="fa fa-calendar"></i> {formatDate(latestEvent?.eventdate)}
          </p>
          <div
            className="parish-event-data"
            onClick={showModal}
            style={{
              cursor: "pointer",
            }}
            dangerouslySetInnerHTML={{
              __html:
                latestEvent?.content
                  .split(" ")
                  .slice(0, 100)
                  .join(" ") +
                (latestEvent?.content.split(" ").length > 100
                  ? '. <a href="#" id="brown-button" className="brown-button"><i className="fa fa-angle-right"></i>Read More</a>'
                  : ""),
            }}
          />
        </div>
      </div>

      {/* Old News Titles */}
      {/* <OlderNews olderEvents={filteredAndSortedEvents} /> */}
      {/* 
      {olderEvents?.length > 0 && (
        <div className="row mt-4">
          <div className="col-lg-12">
            <div className="d-flex flex-wrap">
              {olderEvents?.map((event, index) => (
                <Link
                  to={`/all-parish-news`}
                  key={index}
                  className="text-primary me-2 mb-2"
                  style={{ cursor: "pointer" }}
                >
                  {event?.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
     */}

      {/* Bootstrap Modal */}
      <div className="modal fade" ref={modalRef} tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{latestEvent?.title}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p className="parish-eventdata-data">
                <i className="fa fa-calendar"></i> {formatDate(latestEvent?.eventdate)}
              </p>
              <div
                className="parish-event-data"
                dangerouslySetInnerHTML={{ __html: latestEvent?.content }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParishEvents;


export const OlderNews = ({ olderEvents }) => {
  let filterd_events = olderEvents?.slice?.(1);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  if (filterd_events?.length > 0) return (
    <div className="row mt-4">
      {filterd_events?.map((event, index) => (
        <div className="col-lg-12 mb-4" key={index} style={{ display: "list-item", listStyleType: "disc", marginLeft: "20px", fontSize: "12px" }}>
          <Link
            to={`/all-parish-news`}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            {event?.title}
            {event?.eventdate ? ` - ${formatDate(event?.eventdate)}` : ""}
          </Link>
        </div>
      ))}
    </div>
  );

  return null;
}

